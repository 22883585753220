import React from 'react'
import cx from 'classnames'
import logger from 'logger'
import links from 'links'
import { useDevice } from 'device'
import { useFt } from 'hooks'
import { constants } from 'helpers'

import { Href } from 'components/navigation'
import { Text } from 'components/dataDisplay'

import messages from './messages'


const Basement: React.FunctionComponent = () => {
  const { isMobile } = useDevice()

  const isCheqAiEnabled = useFt(constants.features.cheqAi)

  const year = new Date().getFullYear()

  const legalLinksClassName = cx('flex', {
    'justify-between': isMobile,
    'justify-center': !isMobile,
  })

  const legalLinks: { title: Intl.Message, toTab?: string, onClick?: () => void }[] = [
    {
      title: messages.privacyPolicy,
      toTab: links.privacy,
    },
    {
      title: messages.sellMyInfo,
      toTab: links.ccpa,
    },
    {
      title: messages.termsConditions,
      toTab: links.terms,
    },
  ]

  if (isCheqAiEnabled) {
    legalLinks.push({
      title: messages.cookies,
      onClick: () => {
        try {
          window.Bootstrapper.gateway.openModal()
        }
        catch (error) {
          logger.warn('Click "Cookies" while script is not loaded')
        }
      },
    })
  }

  return (
    <div className={isMobile ? 'mt-24' : 'mt-32'}>
      <Text
        message={{ ...messages.allRightReserved, values: { year } }}
        style={isMobile ? 'p4' : 'p5'}
        color="gray-30"
        align="center"
        html
      />
      <Text
        className="mt-16"
        message={messages.address}
        color="gray-50"
        align="center"
        style="p5"
      />
      <div className={isMobile ? 'mt-32' : 'mt-24'}>
        <div className={legalLinksClassName}>
          {
            legalLinks.map((item, index) => {
              return (
                <Href
                  key={index}
                  className={cx('link-gray-40 cursor-pointer text-p5', !isMobile && 'mx-20')}
                  toTab={item.toTab}
                  message={item.title}
                  onClick={item.onClick}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}


export default Basement
