export default {
  allRightReserved: {
    en: `
      SCENTBIRD ©{year}, All right reserved.<br class="onlyMobile" /> Made with love in New York City.
    `,
  },
  address: {
    en: '401 Cottontail LN, Somerset, NJ 08873',
  },
  privacyPolicy: {
    en: 'Privacy Policy',
  },
  sellMyInfo: {
    en: 'Don’t sell my info',
  },
  termsConditions: {
    en: 'Terms & Conditions',
  },
  cookies: {
    en: 'Cookies',
  },
}
