import React from 'react'
import { useDevice } from 'device'
import { Icon } from 'components/dataDisplay'
import { WidthContainer, WidthContainerCompensator } from 'components/layout'

import { ApplicationStoreButtons, Basement, Nav, SocialNav } from 'compositions/Footer/components'


const Footer: React.FunctionComponent = () => {
  const { isMobile } = useDevice()

  if (isMobile) {
    return (
      <footer className="bg-black px-40 pb-32 pt-56" id="footer">
        <Nav />
        <div className="mb-48 mt-36 text-center">
          <Icon name="other/scentbird-monogram" color="gold-30" />
        </div>
        <ApplicationStoreButtons className="mb-24" />
        <SocialNav />
        <WidthContainerCompensator>
          <Basement />
        </WidthContainerCompensator>
      </footer>
    )
  }

  return (
    <footer className="bg-black pb-32 pt-72" id="footer">
      <WidthContainer size={944}>
        <Nav />
        <div className="mt-48 flex items-center justify-center">
          <Icon className="mr-80" name="other/scentbird-monogram" color="gold-30" />
          <ApplicationStoreButtons className="mr-12" />
          <SocialNav />
        </div>
        <Basement />
      </WidthContainer>
    </footer>
  )
}


export default Footer
