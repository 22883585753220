import React from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'
import { track } from 'analytics'

import { Href } from 'components/navigation'
import { Icon } from 'components/dataDisplay'

import messages from './messages'


type ApplicationStoreButtonsProps = {
  className?: string
}

const ApplicationStoreButtons: React.FunctionComponent<ApplicationStoreButtonsProps> = (props) => {
  const { className } = props
  const intl = useIntl()

  return (
    <div className={cx(className, 'flex items-center justify-center')}>
      <Href
        className="inline-block px-4 text-white"
        toTab="https://app.adjust.com/r7804xw?fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.scentbird"
        title={intl.formatMessage(messages.googlePlay)}
        onClick={() => {
          track('GooglePlay badge click', {
            placement: 'Footer',
          })
        }}
      >
        <Icon name="other/google-play" />
      </Href>
      <Href
        className="inline-block px-4 text-white"
        toTab="https://app.adjust.com/r7804xw?fallback=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fscentbird-beauty-subscription%2Fid1444093439"
        title={intl.formatMessage(messages.appleStore)}
        onClick={() => {
          track('App Store badge click', {
            placement: 'Footer',
          })
        }}
      >
        <Icon name="other/apple-store" />
      </Href>
    </div>
  )
}


export default ApplicationStoreButtons
