import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { track } from 'analytics'
import { Text } from 'components/dataDisplay'
import { NavItem } from 'compositions/navigation'

import useNav from './useNav'


const Nav: React.FunctionComponent = () => {
  const { isMobile } = useDevice()

  const sections = useNav()

  return (
    <nav className={isMobile ? null : 'grid grid-cols-4 grid-rows-2 gap-x-40 gap-y-24'} aria-label="Footer">
      {
        sections.map((section, index) => {
          const { id: titleId, list, withColumns } = section
          const title = section.title as Intl.Message
          const sectionClassName = cx(
            isMobile ? 'mb-48' : 'w-full',
            !isMobile && (index ? 'row-span-2' : 'row-span-1')
          )

          return (
            <div key={title.en} className={sectionClassName}>
              <Text
                id={titleId}
                className="mb-8"
                message={section.title}
                tag="h3"
                style="h9"
                color="gold-30"
              />
              <ul className={withColumns && isMobile ? 'columns-2' : null} aria-labelledby={titleId}>
                {
                  list.map((item: Navigation.Item) => (
                    <li key={item.id} className="text-left">
                      <NavItem
                        className="link-gray-30 inline-block py-12"
                        to={item.to}
                        toTab={item.toTab}
                        href={item.href}
                        title={item.title}
                        style="p3"
                        onClick={() => {
                          track('Footer link click', {
                            blockName: typeof section.title === 'string' ? section.title : section.title.en,
                            title: typeof item.title === 'string' ? item.title : item.title.en,
                            link: item.to || item.toTab,
                          })
                        }}
                        data-testid={item.id}
                      />
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        })
      }
    </nav>
  )
}


export default Nav
