import React from 'react'

import { track } from 'analytics'
import { Icon } from 'components/dataDisplay'
import { Href } from 'components/navigation'


const socialLinks: Navigation.SocialList = [
  {
    id: 'Instagram',
    name: '32/instagram',
    to: 'https://instagram.com/scentbird',
  },
  {
    id: 'Facebook',
    name: '32/facebook',
    to: 'https://www.facebook.com/scentbird',
  },
  {
    id: 'Twitter',
    name: '32/twitter',
    to: 'https://twitter.com/scentbird',
  },
  {
    id: 'Pinterest',
    name: '32/pinterest',
    to: 'https://www.pinterest.com/scentbird/',
  },
  {
    id: 'TikTok',
    name: '32/tiktok',
    to: 'https://www.tiktok.com/@scentbird',
  },
]

const SocialNav: React.FunctionComponent = () => (
  <div className="flex items-center justify-center">
    {
      socialLinks.map((item) => (
        <Href
          key={item.id}
          className="px-16 py-8 text-white"
          toTab={item.to}
          title={item.id}
          onClick={() => {
            track('Social network icon click', {
              placement: 'Footer',
              socialNetwork: item.id,
            })
          }}
        >
          <Icon name={item.name} />
        </Href>
      ))
    }
  </div>
)


export default SocialNav
